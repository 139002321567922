@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Sofia&family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

*, 
*:before, 
*:after {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	margin:0;
	padding:0;
}
html,
body,
#root,
.Section__container___3YYTG,
#main-layout {
  height: 100%;
}

:root {
  --customer-tab-color: #2E3138;
  --internal-tab-color: #3161B1;
  --escalate-tab-color: #A37E29;
  --reference-tab-color: #68C1E7;
  
  --customer-chat-color: #CECFD1;
  --internal-chat-color: #A4C5FF;
  --escalate-chat-color: #FEE8B6;
  --reference-chat-color: #E3F6FE;

  --customer-chat-color-sender: #ECEDEF;
  --internal-chat-color-sender: #DDE6F5;
  --escalate-chat-color-sender: #F8F2E4;
}

body {
  background: #ECEDEF !important;
  color: #5D636F !important;
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
a{
  color: #2D72D2;
  text-decoration: none;
}
.wrap {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 12px;
}
.App {
  text-align: center;
}
#mainList {
  margin-top: 10px;
  max-height: 99vh;
}

#root #mainList .ml-15{
  margin-left: 15px;
}
#root #mainList a {
  font-size: 0.75rem;
  color: inherit;
}
#root #mainList .condition-filter {
  font-size: 0.75rem;
  color:grey ;
  margin: 7px 0 0 0 !important;
}

#root #mainList p.tag-search-list {
  margin: 0px;
}

.loading-blur {
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    display: none \9;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.4;
    transition: all 0.3s;
    pointer-events: auto;
    content: '';
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/*** validate ***/
.MuiFormHelperText-root.Mui-error {
  margin-left: 0;
  margin-right: 0;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}
/*** radio - checkBox ***/
.MuiRadio-root {
  color: #333 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiRadio-colorPrimary.Mui-checked {
  color: #304362 !important;
}
/*** button ***/
.MuiButton-containedPrimary {
  background: #3D547B !important;
  box-shadow: 1px 1px 8px rgba(95, 99, 104, 0.2) !important;
}
.MuiButton-contained.Mui-disabled {
  background: rgba(0, 0, 0, 0.12) !important;
}
.btn-handle-delete-user-agree:hover{
    background-color: #CC4733 !important
}
.btn-confirm-cancel {
    height: 32px;
}
/*** Dialog ***/
.MuiDialog-paperWidthSm {
  max-width: 630px !important;
}

.MuiDialog-paper {
  margin: 32px 12px !important;
}
.wrapDialog .MuiDialog-paper {
  width: 100%;
}
.titleHeader {
  position: relative;
  background: #202b43;
  padding: 14px 55px 14px 24px !important;
  overflow: hidden !important;
}
.titleHeader .title {
  font-size: 20px;
  color: #fff;
}
.titleHeader .close {
  position: absolute;
  top: 4px;
  right: 6px;
}
.titleHeader .close svg {
  color: #fff;
}
/*** layout Ticket List ***/
.wrapContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.sectionMain {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.mainContent {
  width: 100%;
  height: 100%;
  padding-left: 56px;
}
.mainWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.mainTop {
  /* background: #000; */
  padding: 0;
  /* min-height: 70px; */
  border-bottom: 1px solid #E3E5E8;
  /* border-top: 1px solid #E3E5E8; */
}
.mainTop--chat {
  border-top: none;
}
.mainTop .mainFlex .col1,
.mainTop .mainFlex .col2 {
  border: none;
}
.mainTop .mainFlex {
  height: inherit;
  background: #fff;
  padding: 0 16px;
  border-top: 1px solid #E3E5E8;
}
.mainFlex {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow: hidden;
}
.mainFlex .col1,
.mainFlex .col2 {
  height: 100%;
  width: 33.333333%;
}
.scrollBar-main {
  overflow-y: auto;
  height: 100%;
}
.mainFlex .col1 .scrollBar-main {
  padding: 10px;
}
/***/
.accordion .MuiAccordion-rounded:last-child,
.accordion .MuiAccordion-rounded:first-child,
.accordion .MuiPaper-root {
  border-radius: 0;
  box-shadow: none;
}
.accordion .MuiTypography-root {
  font-size: 14px;
  position: relative;
  padding: 6px 0 6px 12px;
  border-bottom: 1px solid #ddd;
}
.accordion .MuiTypography-root:after {
  width: 0px;
  height: 0px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #000;
  position: absolute;
  top: 12px;
  left: 0;
  content: "";
  transition: 200ms all;
}
.accordion .Mui-expanded .MuiTypography-root:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.accordion .MuiAccordion-root.Mui-expanded {
  margin: 0;
}
.accordion .MuiButtonBase-root {
  padding: 0;
  min-height: inherit !important;
}
.accordion .MuiAccordionSummary-content,
.accordion .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
  display: block;
}
.accordion .MuiAccordionDetails-root {
  display: block;
  padding: 10px 0 0;
}
.accordion .facet {
  list-style: none;
}
.accordion .facet li {
  padding: 4px 4px 4px 16px;
  cursor: pointer;
  position: relative;
}
.accordion .facet li:after {
  position: absolute;
  top: 12px;
  left: 4px;
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #f2f2f2;
}
.accordion .facet li:hover {
  background: #f8f8f8;
}
/**** 11/10 ***/
.form-groupCol {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
}
.form-groupCol .form-addTicket {
  width: 50%;
  padding-left: 12px;
}
.form-groupCol .form-addTicket:last-child .form-addTicket-label {
  text-align: right;
}
@media screen and (max-width: 1400px) {
  .form-groupCol .form-addTicket {
    width: 100%;
  }
  .form-groupCol .form-addTicket:last-child .form-addTicket-label {
    text-align: left;
  }
}
/***/
.userRegister {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.userRegister-col {
  width: 50%;
  height: 100%;
  padding: 0 12px;
  overflow-y: auto;
  border-right: 1px #ddd solid;
  background: #f5f5f5;
  background: linear-gradient(to bottom,  #f5f5f5 0%,#ffface 100%);
}
.userRegister .userRegister-col:last-child {
  border: none;
  background: #fff;
}
.formApproval {
  margin-top: 20px;
}
.formApproval .formApproval-label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
  display: inline-block;
}
.formApproval .form-control {
  width: 100%;
}
.formApproval .form-control .MuiSelect-root {
  padding: 12px 32px 12px 12px;
  font-size: 14px;
  background: #fff;
}
.formApproval .form-control input {
  padding: 12px;
  font-size: 14px;
  background: #fff;
}
.formApproval .form-control .MuiInputBase-root {
  background: #fff;
}
.userRegister-group {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.userRegister-group .col {
  width: 50%;
  padding-left: 10px;
}
.formApproval-dateTime {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.formApproval .formApproval-dateTime:last-child {
  margin-bottom: 0;
}
.formApproval-dateTime .formApproval-tlt {
  font-weight: bold;
  font-size: 12px;
  padding-top: 12px;
  margin-bottom: 8px;
  display: inline-block;
  width: 70px;
}
/* .col2 .edit-ticket-btn{
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f2f2f2;
  border-bottom: 1px solid #8080803d;
} */
.formApproval-dateTime .formApproval-inputTime {
  width: calc(100% - 70px);
  min-width: 140px;
  display: flex;
  flex-wrap: wrap;
}
.formApproval-inputTime .formInput {
  width: calc(100% - 52px);
}
.formApproval-inputTime .spanLabel {
  width: 52px;
  font-size: 12px;
  padding-top: 12px;
  padding-left: 6px;
}
.formApproval .lstName-file--detail ul li a {
  width: 100%;
}
.formApproval .lstName-file--detail ul li .lstName-item {
  width: 100%;
  margin-left: 0;
}
.formApproval-add {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.formApproval-add .formApproval-add-label {
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  padding-right: 6px;
}
/**** form select ****/
.form-control--wrap {
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
  color: #2E3138;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  width: 100%;
}
.form-control--hg32 .MuiInputBase-input {
  padding-top: 6px;
  padding-bottom: 7px;
}
.form-control--hg40 .MuiInputBase-input {
  padding-top: 10px;
  padding-bottom: 11px;
}

.form-control-hg48 .MuiInputBase-root .MuiOutlinedInput-input{
  padding-top: 14px;
  padding-bottom: 14px;
}

.form-control--wrap .MuiSelect-icon {
  display: none;
}
.form-control--wrap .MuiSelect-selectMenu {
  min-height: initial;
  position: relative;
  background: #fff;
  font-size: 14px;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}
.form-control--wrap .MuiSelect-selectMenu::after {
  position: absolute;
  top: calc(50% - 3px);
  right: 10px;
  content: "";
  width: 11px;
  height: 6px;
  background: url(/static/media/icon-select.a224935a.svg) no-repeat;
}
.form-control--wrap .MuiOutlinedInput-notchedOutline {
  border: 1px solid #C7CAD1;
}
.form-control--wrap .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #5D636F;
}
.form-control--wrap .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #2D72D2;
}

.form-control--wrap .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
.form-control--wrap .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline,
.searchOptionBody .search-option-tag-content .MuiOutlinedInput-root.Mui-error,
.searchOptionBody .search-option-tag-content .MuiOutlinedInput-root.Mui-error:hover {
  border: 1px solid #CC4733;
}

.searchOptionBody .with-chip-auto .MuiChip-root {
  width: auto!important;
}

.helpdesk-input .MuiAutocomplete-popper .MuiPaper-root {
  background: #FFFFFF;
  box-shadow: 0px 8px 15px 2px rgba(46, 49, 56, 0.1), 0px 0px 2px rgba(46, 49, 56, 0.15);
  border-radius: 6px;
  overflow: auto;
  max-height: 350px;
}
.selectDrop .MuiPaper-root {
  background: #FFFFFF;
  border: 1px solid #E3E5E8;
  box-shadow: 0px 8px 15px 2px rgba(46, 49, 56, 0.1), 0px 0px 2px rgba(46, 49, 56, 0.15);
  border-radius: 6px;
  padding: 4px 0;
  overflow: auto;
  max-height: 350px;
  max-width: 350px;
}
.selectDrop .MuiPaper-root .MuiAutocomplete-listbox, .helpdesk-input .MuiPaper-root .MuiAutocomplete-listbox {
  overflow: initial !important;
}
.helpdesk-input .MuiPaper-root .MuiAutocomplete-listbox {
  max-height: 216px;
}
.selectDrop .MuiList-root {
  padding: 0 !important;
  width: 100% !important;
}

/* .selectDrop .MuiMenu-list {
  padding: 0 !important;
  width: 218px !important;
} */

.selectDrop .MuiAutocomplete-listbox {
  padding: 0 !important;
}
.selectDrop .MuiList-root li,
.selectDrop ul li,
.helpdesk-input .MuiAutocomplete-popper .MuiPaper-root ul li {
  padding: 12px 16px;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #2E3138;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.MuiAutocomplete-noOptions {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-weight: 500;
  font-size: 15px;
}
.selectDrop .MuiList-root li.list-sub-header, .helpdesk-input .MuiList-root li.list-sub-header {
  font-weight: 700;
}
.selectDrop .MuiList-root li.MuiListItem-root.Mui-selected, .helpdesk-input .MuiList-root li.MuiListItem-root.Mui-selected {
  background: #F2F3F5;
}
.selectDrop .MuiList-root li:hover, .helpdesk-input .MuiList-root li:hover {
  background: #F8F8F9;
}
.form-control--wrap .MuiListItem-button:hover {
  background: none;
}
.form-control--wrap .MuiMenuItem-root {
  min-height: initial;
}
.form-control--wrap .MuiMenuItem-root .MuiTouchRipple-root {
  display: none;
}
.form-control--wrap .MuiButtonBase-root {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  padding: 0;
  display: block;
}
.form-control--wrap .Mui-disabled {
  background: #F2F3F5;
  color: #C7CAD1;
}
.form-control--wrap .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border: 1px solid #E3E5E8;
}
.form-control--wrap .MuiOutlinedInput-root.Mui-disabled .MuiSelect-selectMenu::after {
  opacity: 0.3;
}
.form-control--wrapSelect.form-control--hg40 .MuiInputBase-root {
  padding: 0 32px 0 0 !important;
  position: relative;
}
.form-control--wrapSelect.form-control--hg40 .MuiInputBase-root::after {
  position: absolute;
  top: calc(50% - 3px);
  right: 10px;
  content: "";
  width: 11px;
  height: 6px;
  background: url(/static/media/icon-select.a224935a.svg) no-repeat;
}
.form-control--wrapSelect.form-control--hg40 .MuiInputBase-input {
  padding: 12px 0 12px 14px !important;
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
  color: #2E3138;
  font-size: 14px;
  font-weight: 400;
}
.form-control--wrapSelect .MuiAutocomplete-endAdornment {
  display: none;
}
.form-control--wrapSelect .MuiOutlinedInput-notchedOutline {
  border: 1px solid #C7CAD1;
}
.form-control--wrapSelect .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #5D636F;
}
.form-control--wrapSelect .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #2D72D2;
}
.form-control--selectControl {
  width: 100%;
}
.form-control--selectControl .MuiSelect-root {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  position: relative;
  
}
.form-control--selectControl .MuiSelect-root::after {
  position: absolute;
  top: calc(50% - 3px);
  right: 10px;
  content: "";
  width: 11px;
  height: 6px;
  background: url(/static/media/icon-select.a224935a.svg) no-repeat;
}
.form-control--selectControl .MuiOutlinedInput-notchedOutline {
  border: 1px solid #C7CAD1;
  border-radius: 6px;
}
.form-control--selectControl .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #5D636F;
}
.form-control--selectControl.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #2D72D2;
}
.form-control--selectControl .MuiSvgIcon-root {
  display: none;
}
/**** end select ****/
/**** input ****/
.form-control-wrapInput {
  width: 100%;
}
.form-control-wrapInput .MuiInputBase-input {
  padding: 0 14px !important;
  width: 100%;
  font-size: 15px;
  color: #2E3138;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}
.form-control-hg40 .MuiInputBase-input {
  height: 40px;
}
.form-control-hg32 .MuiInputBase-input {
  height: 32px;
}

.form-control-hg48 .MuiInputBase-input {
  height: 48px;
}

.form-control-wrapInput .MuiOutlinedInput-notchedOutline {
  border: 1px solid #C7CAD1;
  border-radius: 6px;
}
.form-control-wrapInput .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #5D636F;
}
.form-control-wrapInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #2D72D2;
}
.form-control-wrapInput .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 1px solid #CC4733
}
.form-control-wrapInput .MuiOutlinedInput-root .Mui-disabled {
  background: #F2F3F5;
  color: #C7CAD1;
}
.form-control-wrapInput .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.form-control-wrapInput .MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline  {
  border: 1px solid #E3E5E8;
}
/**** end input ****/
/**** textarea ****/
.form-control-wrapTextarea .MuiInputBase-root {
  padding: 0;
}
.form-control-hg112 .MuiInputBase-input {
  height: 112px;
}
.form-control-wrapTextarea.textarea-hg .MuiInputBase-input {
  padding: 6px 14px 6px;
  min-height: 26px;
}
.form-control-wrapTextarea .MuiInputBase-input {
  font-size: 15px;
  border-radius: 6px;
  padding: 12px 14px;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

.form-control-wrapTextarea .MuiOutlinedInput-notchedOutline {
  border: 1px solid #C7CAD1;
  border-radius: 6px;
}
.form-control-wrapTextarea .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #5D636F;
}
.form-control-wrapTextarea .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #2D72D2;
}
.form-control-wrapTextarea .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 1px solid #CC4733
}
.form-control-wrapTextarea .MuiOutlinedInput-root .Mui-disabled {
  background: #F2F3F5;
  color: #C7CAD1;
}
.form-control-wrapTextarea .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.form-control-wrapTextarea .MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline  {
  border: 1px solid #E3E5E8;
}
.form-control-wrapTextarea.form-control-wrapTextarea-url {
  width: 83% !important;
}
/**** end textarea ****/
.form-control-wrapPicker {
  width: 100%;
}
.form-control-wrapPicker .MuiOutlinedInput-root {
  border-radius: 6px;
  padding: 0;
}
.form-control-wrapPicker .MuiInputBase-input {
  padding: 0 30px 0 40px !important;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #2E3138;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  border-radius: 6px;
}
.form-control-wrapPicker .MuiOutlinedInput-notchedOutline {
  border: 1px solid #C7CAD1;
  border-radius: 6px;
}
.form-control-wrapPicker .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #5D636F;
}
.form-control-wrapPicker .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #2D72D2;
}
.form-control-wrapPicker .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 1px solid #CC4733
}
.form-control-wrapPicker .MuiOutlinedInput-root .Mui-disabled {
  background: #F2F3F5;
  color: #C7CAD1;
}
.form-control-wrapPicker .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
.form-control-wrapPicker .MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline  {
  border: 1px solid #E3E5E8;
}

.form-control-wrapPicker .MuiInputAdornment-root {
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  max-height:  initial !important;
}
.form-control-wrapPicker.form-control-hg40 .MuiInputAdornment-root {
  height: 40px !important;
  width: 40px;
}
.form-control-wrapPicker .MuiButtonBase-root {
  padding: 0;
  width: 100%;
  height: 100%;
  background: url(/static/media/icon-picker.21f25fcf.svg) no-repeat center;
}
.form-control-wrapPicker .MuiButtonBase-root .MuiIconButton-label {
  display: none;
}
.form-control-wrapPicker .MuiOutlinedInput-root.Mui-disabled .MuiInputBase-input {
  background: #F2F3F5;
  color: #C7CAD1;
  border: 1px solid #E3E5E8;
}
.MuiButtonBase-root.button-url {
  border: none !important;
  background: transparent !important;
  padding: 4px 5px;
  min-width: 0;
}
.MuiButtonBase-root.button-url .MuiTouchRipple-root{
  display: none;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.itemDatePicker {
  position: relative;
}

.class_disabled {
  pointer-events: none;
  opacity: 0.4;
  background-color: #C7CAD1;
}
.itemDatePicker .close-DatePicker {
  padding: 0;
  min-width: initial;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 13px;
  right: 12px;
  display: none;
}
.itemDatePicker:hover .close-DatePicker {
  display: flex;
  align-items: center;
  justify-content: center;
}
/****/
.head-btn {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e5e8;
}
.head-btn .MuiButtonBase-root {
  padding: 0;
  color: #2D72D2;
  font-weight: 500;
  font-size: 15px;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}
.head-btn .MuiButtonBase-root:hover {
  background: none;
}
.head-btn .MuiButtonBase-root .MuiButton-label {
  display: flex;
}
.head-btn .MuiButtonBase-root .btn-plus-icon {
  margin-right: 10px;
  width: 12px;
  height: 12px;
  min-width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-btn .head-btn-wrap {
  display: flex;
}
.head-btn .head-btn-wrap .btn-cancel {
  margin-right: 16px;
}
.head-btn .head-btn-wrap .btn-submit .MuiButtonBase-root {
  width: 80px;
}
.head-btn .head-btn-wrap .btn-cancel .MuiButtonBase-root {
  width: 107px;
}

/* hide iframe */ 
/* iframe {
  display: none;
} */

/* notistack */
button.notistack-button {
  color: #fff;
}
button.notistack-button.MuiButton-root:hover {
  background-color: unset;
}

span.shared-ticket-link {
  cursor: pointer;
  text-decoration: underline;
  color: #2D72D2
}
.chat-message.from-me span.shared-ticket-link {
  color: unset;
}

@media screen and (max-width: 926px) {
  .sp-dnone {
    display: none;
  }
  .mainWrap {
    min-width: auto;
  }
  .mainFlex .col1,
  .mainFlex .col2 {
    width: 100%;
  }
  .mainContent {
    width: 100%;
    order: 1;
    padding: 0;
  }
  .mainFlex .bod-left {
    border: none;
  }
  .sectionMain {
    flex-direction: column;
    flex-wrap: inherit;
  }
  .mainTop {
    min-height: inherit;
  }
  .itemAddticket {
    margin-top: 0;
  }
  /* .userRegister-col {
    height: calc(100% - 50px);
  } */
  .head-btn {
    flex-wrap: wrap;
  }
  .head-btn-add,
  .head-btn-wrap {
    width: 100%;
  }
  .head-btn .head-btn-wrap {
    margin-top: 24px;
  }
  .head-btn .head-btn-wrap .btn-cancel,
  .head-btn .head-btn-wrap .btn-submit {
    width: 50%;
  }
  .head-btn .head-btn-wrap .btn-submit .MuiButtonBase-root,
  .head-btn .head-btn-wrap .btn-cancel .MuiButtonBase-root {
    width: 100%;
  }
}
.ticket-link-detail {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

/* css common */
.btn-primary{
  background: #2D72D2 !important;
  border-radius: 8px !important;
  color: white !important;
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
  box-shadow: none !important;
  font-size: 15px;
}
.btn-primary:hover {
  background: #245BA8 !important;
}
.btn-primary.Mui-disabled {
  background: #D5E3F6 !important;
}
.btn-delete{
  background: #CC4733 !important;
  border-radius: 8px !important;
  color: white !important;
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
  box-shadow: none !important;
  font-size: 15px;
}
.btn-delete:hover {
  background: #cf341c !important;
}
.btn-delete.Mui-disabled {
  background: #D5E3F6 !important;
}
.btn-outline-primary {
  color: #2D72D2 !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #2D72D2 !important;
  font-size: 15px;
}
.btn-outline-primary:hover {
  background: #2D72D2 !important;
  color: #fff !important;
}
.btn-outline-primary.Mui-disabled {
  background: #fff !important;
  border: 1px solid #D5E3F6 !important;
  color: #D5E3F6 !important;
}
.btn-light {
  background: #F2F3F5 !important;
  border-radius: 8px !important;
  color: #5D636F !important;
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
  box-shadow: none !important;
  font-size: 15px;
  border: none !important;
}
.btn-light:hover {
  background: #E3E5E8 !important;
}
.btn-light.Mui-disabled {
  background: #F2F3F5 !important;
  color: #C7CAD1 !important;
}

.btn-outline-light {
  color: #2E3138 !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #E3E5E8 !important;
}
.btn-outline-light:hover {
  background: #2E3138 !important;
  color: #fff !important;
}
.btn-outline-light.Mui-disabled {
  background: #fff !important;
  border: 1px solid #D5E3F6 !important;
  color: #D5E3F6 !important;
}

.btn-hg32 {
  height: 32px;
  border-radius: 6px !important;
  font-weight: 500;
  font-size: 15px !important;
  padding: 0 8px !important;
}

.btn-hg20 {
  height: 20px;
  border-radius: 6px !important;
  font-weight: 500;
  font-size: 13px !important;
  padding: 0 8px !important;
}
.radio-control{
  color: #C7CAD1 !important;
}
.radio-control.Mui-checked{
  color: #2D72D2 !important;
}
.custom-checkbox {
  margin: 0 0 0 -4px !important;
}
.custom-checkbox .MuiButtonBase-root {
  padding: 0 !important;
}
.custom-checkbox .MuiSvgIcon-root {
  fill: #C7CAD1;
}
.custom-checkbox .Mui-checked .MuiSvgIcon-root {
  fill: #2D72D2;
}
.custom-checkbox .MuiTypography-root {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  color: #2E3138;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}
.custom-checkbox .MuiIconButton-colorSecondary  {
  color: #f2f3f5 !important;
}
.custom-checkbox .MuiButtonBase-root {
  margin-right: 6px;
}
.custom-checkbox:hover .MuiButtonBase-root {
  background: #f2f3f5;
  border-radius: 2px;
}
/****/
.custom-radio {
  margin: 0 !important;
}
.custom-radio .MuiButtonBase-root {
  padding: 0 !important;
}
.custom-radio .MuiSvgIcon-root {
  display: none;
}
.custom-radio .MuiIconButton-label {
  position: relative;
  width: 22px;
  height: 22px;
}
.custom-radio .MuiIconButton-label::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: url(/static/media/icon-radio.33ef3c3b.svg) no-repeat center;
}
.custom-radio .Mui-checked .MuiIconButton-label::after {
  background: url(/static/media/icon-radioCheck.d943202a.svg) no-repeat center;
}
.custom-radio .MuiTypography-root {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  color: #2E3138;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}
.custom-radio .MuiButtonBase-root {
  margin-right: 6px;
}
.custom-radio:hover .MuiButtonBase-root {
  background: #f2f3f5;
  border-radius: 50%;
}

/* don't allow disable button but still change background color; */
.custom-radio:hover:not(:disabled) .MuiButtonBase-root, .custom-checkbox:hover:not(:disabled) .MuiButtonBase-root{
  background: none
}

.fields-disabled {
  background-color: #f2f3f5 !important;
  color: #2e3138 !important;
}
.flex-column {
  display: flex;
  flex-flow: column nowrap;
}
/* end css common */
@media screen and (max-width: 767px) {
  .titleHeader .title {
    font-size: 15px;
  }
  .titleHeader .close {
    padding: 8px;
  }
  .formUpload .MuiButtonBase-root img {
    max-width: 60px;
  }
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #E1E3E6 !important;
}

#cursor-loading {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  cursor: wait;
  display: none;
}
#cursor-loading.show {
  display: block;
}

.max-w-370 {
  max-width: 370px;
}

/* ========================================== CLASS COMMON ================================================== */
.color-red {
  color: red;
}
.color-white {
  color: white;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}

.align-items-center{
  align-items: center;
}

.justify-content-center{
  justify-content: center;
}

.justify-content-start{
  justify-content: flex-start;
}

/* Spacing */
.mt-2 {
  margin-top: 0.5rem !important;
}

.my-1{
  margin-top: 0.25rem; /* 4px */
  margin-bottom: 0.25rem; /* 4px */
}

.mx-3 {
  margin-left: 0.75rem;
  /* 12px */
  margin-right: 0.75rem;
  /* 12px */
}

/*padding, margin*/
.p-3 {
  padding: 3px;
}

.px-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.py-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.my-3 {
  margin-top: 0.75rem;
  /* 12px */
  margin-bottom: 0.75rem;
  /* 12px */
}

.mr-1{
  margin-right: 0.25rem;
}
/* border */
.border-solid {
  border-style: solid;
}
/*Border Radius*/
.rounded-sm {
  border-radius: 0.125rem; /* 2px */
}

.rounded-md {
  border-radius: 0.375rem; /* 6px */
}

/*Border Width*/
.border-0 {
  border-width: 0px;
}

.border {
  border-width: 1px;
}	

.border-2 {
  border-width: 2px;
}

/*border color*/
.border-slate-300 {
  border-color: rgb(203 213 225);
}

.border-red-600{
  border-color: rgb(220 38 38);
}


/*Color*/
.text-blue-500 {
  color: rgb(45, 114, 210)!important;
}

.text-red-600 {
  color: rgb(220 38 38)!important;
}

.text-slate-400 {
  color: rgb(148 163 184);
}

/*text size*/
.text-xl {
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.75rem;
  /* 28px */
}

.text-lg {
  font-size: 1.125rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
}
/* CKEditor 5 */
.ck-powered-by {
  display: none !important;
}

.ckeditor5-readonly .ck.ck-editor__editable {
  box-shadow: none !important;
  border: none !important;
}

.ckeditor5-readonly .ck-editor__top,
.ckeditor5-readonly .raw-html-embed__buttons-wrapper,
.ckeditor5-readonly .ck-widget__selection-handle,
.ckeditor5-readonly .ck-widget.raw-html-embed:before
{
  display: none !important;
}

.ckeditor5-readonly .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected,
.ckeditor5-readonly .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover {
  outline-color: transparent !important;
}

.ckeditor5-readonly .ck-widget.raw-html-embed {
  outline: none !important;
}

.ckeditor5-readonly.public-knowledge-detail .ck.ck-editor__editable_inline {
  padding: 0px !important;
}

.sdesk-editor.ckeditor5-readonly .ck-restricted-editing_mode_standard {
  background-color: #F2F3F5 !important;
}

.editor .ck-restricted-editing_mode_standard {
  min-height: 150px !important;
}

.editor .ck.ck-editor__main ol::before, .editor .ck.ck-editor__main ul::before, .ck-content ol, .ck-content ul {
  margin: revert;
  padding: revert;
  -webkit-padding-start: 30px;
          padding-inline-start: 30px;
}

.editor .ck.ck-list__item .ck.ck-button__label
{
  font-size: 12px !important;
}

.ckeditor5-readonly figure.table.ck-widget,
.ckeditor5-readonly figure.table.ck-widget table {
  pointer-events: none;
}

.editor .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border: none;
}

.editor .ck.ck-dropdown .ck-dropdown__panel {
  max-width: 300px;
}

.editor .ck-media-form .ck-labeled-field-view__input-wrapper > .ck.ck-input {
  min-width: 200px;
}

.editor .ck.ck-list-styles-list > .ck.ck-button {
  width: 38px;
  height: 38px;
}

.editor .ck.ck-character-grid {
  max-height: 75px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 350px;
}

.editor .ck.ck-character-grid .ck-character-grid__tiles {
  grid-template-columns: repeat(6, 1fr);
}

.ck-dropdown.ck-font-size-dropdown ul.ck-list {
  max-height: 180px;
  overflow-y: scroll;
}

.field-url-link {
  max-width: 96%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.ck-editor .ck-content h1 span {
  font-size: unset !important;
}

.ck-editor .ck-content h2 span {
  font-size: unset !important;
}

.ck-editor .ck-content h3 span {
  font-size: unset !important;
}

.ck-editor .ck-content h4 span {
  font-size: unset !important;
}

.ck-editor .ck-content h5 span {
  font-size: unset !important;
}

.ck-editor .ck-content h6 span {
  font-size: unset !important;
}
.ck-editor .ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: static;
}

.ck.ck-button:not(.ck-disabled):active, a.ck.ck-button:not(.ck-disabled):active {
  background: transparent;
}
.ck.ck-button:active, .ck.ck-button:focus, a.ck.ck-button:active, a.ck.ck-button:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

/* Common field Assignee */
.assign-filter-text-common {
  height: 100%;
}

.assignee-field-common-content .form-control--wrapSelect.form-control--hg40 .MuiInputBase-input {
  min-width: 0;
}

.font-family-common {
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
  color: #2e3138;
}

.markdown-redirect {
  color:  #2D72D2;
  text-decoration: underline;
  cursor: pointer;
  word-break: break-all;
}
